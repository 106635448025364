












































import { Component, Vue, Prop } from 'vue-property-decorator'
import firebase from '@/plugins/firebase'
import { Company } from '@/models/company'
import { logAdd } from '@/models/log'

@Component
export default class CompanyUpdateDescription extends Vue {
  @Prop({ type: Object, required: true }) doc!: firebase.firestore.QueryDocumentSnapshot<Company>

  menu = false
  description = this.item.description

  get item (): Company {
    return this.doc.data()
  }

  save (): void {
    this.doc.ref.set({ description: this.description }, { merge: true })
    this.menu = false
    logAdd('update', '회사 설명 변경', `${this.item.description} => ${this.description}`, 'admin')
  }
}
