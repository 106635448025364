


























































import { Component, Vue, Ref, Watch } from 'vue-property-decorator'
import companyCollection, { Company } from '@/models/company'
import { logAdd } from '@/models/log'

@Component<CompanyAdd>({
  mounted () {
    // console.log('mount')
  }
})
export default class CompanyAdd extends Vue {
  @Ref('form') form!: { validate(): boolean; reset(): void }
  dialog = false
  loading = false
  name = ''
  description = ''

  @Watch('dialog')
  async onChangeDialog (opened: boolean): Promise<void> {
    if (!opened) return
    this.$nextTick(() => {
      this.form.reset()
    })
  }

  async submit (): Promise<void> {
    if (!this.form.validate()) return
    try {
      this.loading = true
      companyCollection.add(new Company(this.name, this.description))
      this.dialog = false
      logAdd('create', '회사 추가', `${this.name}`, 'admin', true)
    } finally {
      this.loading = false
    }
  }
}
