




















import { Component, Vue, Prop } from 'vue-property-decorator'
import firebase from '@/plugins/firebase'
import { Group } from '@/models/group'
import DisplayTime from '@/components/common/DisplayTime.vue'
import userCollection from '@/models/user'
import CompanyGroupUpdateName from '@/components/manage/CompanyGroupUpdateName.vue'
import CompanyGroupUpdateDescription from '@/components/manage/CompanyGroupUpdateDescription.vue'
import AuthStore from '@/store/AuthStore'

@Component({
  components: {
    DisplayTime,
    CompanyGroupUpdateName,
    CompanyGroupUpdateDescription
  }
})
export default class CompanyGroupItem extends Vue {
  @Prop({ type: Object, required: true }) doc!: firebase.firestore.QueryDocumentSnapshot<Group>

  get item (): Group {
    return this.doc.data()
  }

  async remove (): Promise<void> {
    const r = confirm('정말 삭제 하시겠습니까?')
    if (!r) return
    const sn = await userCollection.where('groups', 'array-contains', this.doc.ref).limit(1).get()
    if (!sn.empty) throw Error(`${this.item.name}으로 지정한 사용자가 있습니다`)

    if (AuthStore.firebaseUser) {
      await userCollection.doc(AuthStore.firebaseUser.uid).update({
        groups: firebase.firestore.FieldValue.arrayRemove(this.doc.ref)
      })
    }

    this.doc.ref.delete()
  }
}
