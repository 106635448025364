



















import { Component, Vue } from 'vue-property-decorator'
import firebase from '@/plugins/firebase'
import companyCollection, { Company } from '@/models/company'
import CompanyItem from '@/components/manage/CompanyItem.vue'
import AuthStore from '@/store/AuthStore'

@Component<CompanyList>({
  components: {
    CompanyItem
  }
  // mounted () {
  //   this.subscribe()
  // },
  // destroyed () {
  //   if (this.unsubscribe) this.unsubscribe()
  // }
})
export default class CompanyList extends Vue {
  get docs (): firebase.firestore.DocumentSnapshot<Company>[] {
    return AuthStore.companies || []
  }
  // unsubscribe: firebase.Unsubscribe | null = null
  // docs: firebase.firestore.QueryDocumentSnapshot<Company>[] = []

  // get items (): Firmware[] {
  //   return this.docs.map(doc => Object.assign(doc.data(), { id: doc.id }))
  // }

  // subscribe (): void {
  //   this.unsubscribe = companyCollection.limit(100).onSnapshot(sn => {
  //     this.docs = sn.docs
  //   })
  // }
}
