

















import { Component, Vue } from 'vue-property-decorator'
import CompanyList from '@/components/manage/CompanyList.vue'
import CompanyAdd from '@/components/manage/CompanyAdd.vue'
import AuthStore from '@/store/AuthStore'

@Component({
  components: { CompanyList, CompanyAdd }
})
export default class CompanyIndex extends Vue {
  get isAdmin (): boolean {
    return AuthStore.isAdmin
  }
}
