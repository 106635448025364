


























































import { Component, Vue, Prop, Ref } from 'vue-property-decorator'
import firebase from '@/plugins/firebase'
import { Group } from '@/models/group'
import { logAdd } from '@/models/log'

@Component
export default class CompanyGroupUpdateName extends Vue {
  @Prop({ type: Object, required: true }) doc!: firebase.firestore.QueryDocumentSnapshot<Group>
  @Ref('form') form!: { validate(): boolean }

  menu = false
  name = this.item.name
  description = this.item.description
  rules = [
    (v: string): boolean | string => !!v || '이름을 입력해야합니다'
  ]

  get item (): Group {
    return this.doc.data()
  }

  save (): void {
    if (!this.form.validate()) return
    this.doc.ref.set({ name: this.name, description: this.description }, { merge: true })
    this.menu = false
    logAdd('update', '그룹 변경', `${this.item.name} => ${this.name} & ${this.item.description} => ${this.description}`, 'admin')
    // this.$toast.success('good')
  }
}
