


























































import { Component, Vue, Ref, Watch, Prop } from 'vue-property-decorator'
import firebase from '@/plugins/firebase'
import { Company } from '@/models/company'
import groupCollection, { Group } from '@/models/group'
import { logAdd } from '@/models/log'
import userCollection from '@/models/user'
import AuthStore from '@/store/AuthStore'

@Component<CompanyGroupAdd>({
  mounted () {
    // console.log('mount')
  }
})
export default class CompanyGroupAdd extends Vue {
  @Prop({ type: Object, required: true }) doc!: firebase.firestore.QueryDocumentSnapshot<Company>

  @Ref('form') form!: { validate(): boolean; reset(): void }
  dialog = false
  loading = false
  name = ''
  description = ''

  @Watch('dialog')
  async onChangeDialog (opened: boolean): Promise<void> {
    if (!opened) return
    this.$nextTick(() => {
      this.form.reset()
    })
  }

  async submit (): Promise<void> {
    if (!this.form.validate()) return
    try {
      this.loading = true

      const doc = await groupCollection.add(new Group(this.doc.ref, this.name, this.description))
      if (AuthStore.firebaseUser) {
        await userCollection.doc(AuthStore.firebaseUser.uid).update({
          groups: firebase.firestore.FieldValue.arrayUnion(doc)
        })
      }
      this.dialog = false
      logAdd('create', '그룹 추가', `${this.name}`, 'admin', true)
    } finally {
      this.loading = false
    }
  }
}
