




































































import { Component, Vue, Prop } from 'vue-property-decorator'
import firebase from '@/plugins/firebase'
import { Company } from '@/models/company'
import DisplayTime from '@/components/common/DisplayTime.vue'
import CompanyUpdateName from '@/components/manage/CompanyUpdateName.vue'
import CompanyUpdateDescription from '@/components/manage/CompanyUpdateDescription.vue'
import userCollection from '@/models/user'
import CompanyGroupList from '@/components/manage/CompanyGroupList.vue'
import CompanyGroupAdd from '@/components/manage/CompanyGroupAdd.vue'

@Component({
  components: {
    DisplayTime,
    CompanyUpdateName,
    CompanyUpdateDescription,
    CompanyGroupList,

    CompanyGroupAdd
  }
})
export default class CompanyItem extends Vue {
  @Prop({ type: Object, required: true }) doc!: firebase.firestore.QueryDocumentSnapshot<Company>

  get item (): Company {
    return this.doc.data()
  }

  async remove (): Promise<void> {
    const r = confirm('정말 삭제 하시겠습니까?')
    if (!r) return
    const sn = await userCollection.where('companies', 'array-contains', this.doc.ref).limit(1).get()
    if (!sn.empty) throw Error(`${this.item.name}으로 지정한 사용자가 있습니다`)

    this.doc.ref.delete()
  }
}
