










import { Component, Vue, Prop } from 'vue-property-decorator'
import firebase from '@/plugins/firebase'
import { Company } from '@/models/company'
import { Group } from '@/models/group'
import CompanyGroupItem from '@/components/manage/CompanyGroupItem.vue'
import AuthStore from '@/store/AuthStore'

@Component<CompanyGroupList>({
  components: {
    CompanyGroupItem
  }
  // mounted () {
  //   this.subscribe()
  // },
  // destroyed () {
  //   if (this.unsubscribe) this.unsubscribe()
  // }
})
export default class CompanyGroupList extends Vue {
  @Prop({ type: Object, required: true }) doc!: firebase.firestore.QueryDocumentSnapshot<Company>
  unsubscribe: firebase.Unsubscribe | null = null

  get docs (): firebase.firestore.DocumentSnapshot<Group>[] {
    return AuthStore.groups.filter(group => {
      const item = group.data()
      if (!item) return
      return item.company.id === this.doc.id
    })
  }
  // docs: firebase.firestore.QueryDocumentSnapshot<Group>[] = []

  // subscribe (): void {
  //   this.unsubscribe = groupCollction.where('company', '==', this.doc.ref).limit(100).onSnapshot(sn => {
  //     this.docs = sn.docs
  //   })
  // }
}
